<template>
  <div>
    <spinner v-if="loading" color="black" />
    <template v-else>
      <entity-card
        :entity="entity"
        :fields="fields"
        :title="`${$t('order.label')} ${entity.invoiceNumber}`"
      >
        <template #action>
          <div class="entity-detail-page-actions">
            <router-link
              v-t="'general.action.back'"
              class="button white inline-block small mr-2"
              :to="{name: 'store-shifts-shift-id'}"
            />
          </div>
        </template>
      </entity-card>
      <h2>{{ $t('lineItem.label', 2) }}</h2>
      <entity-table
        :fields="lineItemDisplayFields"
        :entities="entity.lineItems"
      >
        <template #type="{entity: lineItem}">
          <template v-if="lineItem.type === 10">
            &nbsp;
          </template>
          <template v-else-if="lineItem.type === 20">
            <font-awesome-icon icon="percent" />
          </template>
          <template v-else-if="lineItem.type === 30">
            <font-awesome-icon icon="badge" />
          </template>
        </template>
        <template #amount="{entity: lineItem}">
          <template v-if="lineItem.weight">
            {{ lineItem.weight }}g
          </template>
          <template v-else>
            {{ lineItem.amount }}
          </template>
        </template>
        <template #description="{entity: lineItem}">
          <template v-if="lineItem.type === 10">
            <admin-entity-link :to="lineItem.product">
              {{ lineItem.description }}
            </admin-entity-link>
          </template>
          <template v-else-if="lineItem.type === 20">
            <admin-entity-link :to="lineItem.discount">
              {{ lineItem.description }}
            </admin-entity-link>
          </template>
          <template v-else-if="lineItem.type === 30">
            <admin-entity-link :to="lineItem.promotion">
              {{ lineItem.description }}
            </admin-entity-link>
          </template>
        </template>
        <template #vatRate="{entity: lineItem}">
          {{ parseFloat(lineItem.vatRate) * 100 }}%
        </template>

        <template #lineItemDiscounts="{entity: lineItem}">
          <div v-for="lineItemDiscount in lineItem.lineItemDiscounts" :key="lineItemDiscount.id">
            <span class="mr-2">
              <admin-entity-link v-if="lineItemDiscount.promotion" :to="lineItemDiscount.promotion">
                <font-awesome-icon icon="badge" />
              </admin-entity-link>
              <admin-entity-link v-else-if="lineItemDiscount.discount" :to="lineItemDiscount.discount">
                <font-awesome-icon icon="percent" />
              </admin-entity-link>
            </span>
            <span>
              {{ formatPrice(lineItemDiscount.amount) }}
            </span>
          </div>
        </template>
      </entity-table>

      <h2>{{ $t('orderPayment.label', 2) }}</h2>
      <entity-table
        :fields="orderPaymentDisplayFields"
        :entities="entity.orderPayments"
      >
        <template #paymentMethod="{entity: orderPayment}">
          {{ $t(`orderPayment.paymentMethods.${orderPayment.paymentMethod}`) }}
        </template>
        <template #merchantReceipt="{entity: orderPayment}">
          <template v-if="orderPayment.paymentMethod === 10">
            <pre class="whitespace-pre-wrap">
{{ orderPayment.merchantPaymentInformation }}
            </pre>
          </template>
        </template>
        <template #consumerReceipt="{entity: orderPayment}">
          <template v-if="orderPayment.paymentMethod === 10">
            <pre class="whitespace-pre-wrap">
{{ orderPayment.printerPaymentInformation }}
            </pre>
          </template>
        </template>
        <template #actions="{entity: orderPayment}">
          <template v-if="orderPayment.paymentLog.length > 0">
            <a href="#" @click="openLogModal(orderPayment)"><font-awesome-icon icon="list" class="icon" /></a>
          </template>
        </template>
      </entity-table>
    </template>
    <modal :open="showLogModal" @close="closeLogModal">
      <pre>
{{ paymentLog }}
      </pre>
    </modal>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import axios from '@/services/axios';
import EntityCard from '@/components/shared/EntityCard.vue';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import Spinner from '@/components/utils/spinner.vue';
import { formatDate } from '@/utils/date-utils';
import formatPrice from '@/utils/formatPrice';
import AdminEntityLink from '@/components/utils/AdminEntityLink.vue';
import Modal from '@/components/utils/Modal.vue';

export default {
  name: 'OrderDetailPage',
  components: {
    AdminEntityLink, EntityCard, EntityTable, Spinner, Modal,
  },
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    const loading = ref(false);
    const entity = ref(null);
    const fields = ref([
      { key: 'orderTime', label: t('order.orderTime'), formatter: (v) => formatDate(v, 'dd.MM.yyyy HH:mm:ss') },
      { key: 'total', label: t('order.total'), formatter: (v) => formatPrice(v) },
      { key: 'roundingAmount', label: t('order.roundingAmount'), formatter: (v) => formatPrice(v) },
    ]);

    const lineItemDisplayFields = ref([
      { key: 'type', label: '' },
      { key: 'description', label: t('lineItem.description') },
      { key: 'unitPrice', label: t('lineItem.unitPrice'), displayFilter: (v) => formatPrice(v) },
      { key: 'amount', label: t('lineItem.amount') },
      { key: 'lineItemDiscounts', label: t('lineItem.lineItemDiscounts') },
      { key: 'totalPrice', label: t('lineItem.totalPrice'), displayFilter: (v) => formatPrice(v) },
      { key: 'vatRate', label: t('lineItem.vatRate') },
    ]);

    const orderPaymentDisplayFields = ref([
      { key: 'paymentMethod', label: t('orderPayment.paymentMethod') },
      { key: 'paidAmount', label: t('orderPayment.paidAmount'), displayFilter: (v) => formatPrice(v) },
      { key: 'merchantReceipt', label: t('orderPayment.merchantDetails') },
      { key: 'consumerReceipt', label: t('orderPayment.consumerDetails') },
      { key: 'actions', label: '' },
    ]);

    const loadData = async () => {
      loading.value = true;

      try {
        const { data } = await axios.get(`/orders/${route.params.orderId}`);
        entity.value = data;
      } catch (err) {
        console.error(err, err.message, err.response);
      }

      loading.value = false;
    };

    loadData();

    return {
      loading,
      entity,
      fields,
      loadData,
      lineItemDisplayFields,
      orderPaymentDisplayFields,
      formatPrice,
    };
  },
  data() {
    return {
      showLogModal: false,
      paymentLog: '',
    };
  },
  methods: {
    closeLogModal() {
      this.showLogModal = false;
    },
    openLogModal(orderPayment) {
      this.showLogModal = true;
      this.paymentLog = orderPayment.paymentLog;
    },
  },
};
</script>
